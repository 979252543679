import SpringLandingPage from "./spring-template/landing-page";

function WinsonModule() {
  return (
    <div>
      <SpringLandingPage />
    </div>
  );
}

export default WinsonModule;
