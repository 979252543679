export const ccDB = [
  {
    username: "nm2",
    published: false,
  },
];

export const schoolDB = [
  {
    username: "tpps",
    published: false,
  },
  {
    username: "presidency",
    published: false,
  },
];
export const tutorDB = [
  {
    username: "raju",
    published: false,
  },
  {
    username: "presidency",
    published: false,
  },
];
